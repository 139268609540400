import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(private dialog: MatDialog) {}

  openDialog(component: any, data: any = {}, opts = {}) {
    return this.dialog.open(component, {
      data,
      restoreFocus: false,
      autoFocus: false,
      ...opts,
    });
  }
}
