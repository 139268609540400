import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { USER_CONFIG } from '../user.config';
import { StoreRequestStateUpdater } from '@ba-infrastructure/api-client/types/store-request-state-updater';
import { ApiService } from '@ba-infrastructure/api-client/api.service';
import { LoginDto } from '@ba-core/user/types/dto/login.dto';
import { getUrlWithParams } from '@ba-shared/helpers/endpoint.helpers';
import { User } from 'src/app/features/admin/user/types/user';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class UserEndpoint {
    constructor(private readonly apiService: ApiService) { }

    requestForgotPwd(
        email: string,
        requestStateUpdater: StoreRequestStateUpdater,
    ): Observable<void> {
        const request = USER_CONFIG.requests.requestForgotPwd;
        return this.apiService.post(request, { email }, requestStateUpdater);
    }

    verifyAuthCode(activationCode: string, requestStateUpdater: StoreRequestStateUpdater): Observable<any> {
        const request = USER_CONFIG.requests.verifyAuthCode;

        let params = new HttpParams();
        params = params.set('ActivationCode', activationCode);
        return this.apiService.get(request, requestStateUpdater, params);
    }

    resetPassword(
        resetPwdPayload: { email: string, password: string, activationCode: string },
        requestStateUpdater: StoreRequestStateUpdater,
    ): Observable<void> {
        const request = USER_CONFIG.requests.resetPassword;
        return this.apiService.post(request, resetPwdPayload, requestStateUpdater);
    }

    setPassword(
        password: string,
        requestStateUpdater: StoreRequestStateUpdater,
    ): Observable<void> {
        const request = USER_CONFIG.requests.setPassword;
        return this.apiService.post(request, { password }, requestStateUpdater);
    }

    login(
        loginDto: LoginDto,
        requestStateUpdater: StoreRequestStateUpdater,
    ): Observable<User> {
        const request = USER_CONFIG.requests.login;
        return this.apiService.post(request, loginDto, requestStateUpdater);
    }

    getUser(requestStateUpdater: StoreRequestStateUpdater): Observable<User> {
        const request = USER_CONFIG.requests.getUser;
        return this.apiService.get(request, requestStateUpdater);
    }

    getApplicationUser(applicationUserId: string, requestStateUpdater: StoreRequestStateUpdater): Observable<User> {
        const request = { ...USER_CONFIG.requests.getApplicationUser };
        request.url = getUrlWithParams(request.url, { applicationUserId });
        return this.apiService.get(request, requestStateUpdater);
    }
}
