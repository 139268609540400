import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthGuard } from '@ba-core/authentication/auth.guard';
import { UserStore } from '@ba-core/user/services/user.store';
import { UserEndpoint } from '@ba-core/user/services/user.endpoint';
import { StaticEndpoint } from './static/services/static.endpoint';
import { StaticStore } from './static/services/static.store';

@NgModule({
    declarations: [],
    imports: [CommonModule],
    providers: [
        AuthGuard, 
        UserStore, 
        UserEndpoint,
        StaticStore,
        StaticEndpoint
    ],
})
export class CoreModule {
    constructor(
        @Optional()
        @SkipSelf()
        parentModule: CoreModule,
    ) {
        if (parentModule) {
            throw new Error('Only import in AppModule');
        }
    }
}
