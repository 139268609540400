import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserStore } from '@ba-core/user/services/user.store';
import { DialogService } from '@ba-shared/services/dialog.service';
import { SnackBarService } from '@ba-shared/services/snack-bar.service';
import { of } from 'rxjs';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';
import { UserStatusEnum } from './features/admin/user/types/user';
import { AuthMessageDialogComponent } from './features/authentication/views/auth-message-dialog/auth-message-dialog.component';

@Component({
  selector: 'ba-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'b13-angular';

  constructor(
    private userStore: UserStore,
    private snackbarService: SnackBarService,
    private router: Router,
    private dialogService: DialogService,
  ) {}

  ngOnInit() {
    this.checkUserStatus();
  }

  checkUserStatus() {
    const source = this.userStore.user$.pipe(
      distinctUntilChanged(
        (cur, pre) => JSON.stringify(cur) === JSON.stringify(pre),
      ),
      switchMap((user) => {
        if (!user) {
          return of(null);
        }

        return this.userStore.intervalCheckUserStatus();
      }),
    );

    source.subscribe((userStatus) => {
      if (userStatus === UserStatusEnum.Archived) {
        this.handleArchvicedUser();
      }
    });
  }

  handleArchvicedUser() {
    this.userStore.purgeAuth();

    this.dialogService.openDialog(AuthMessageDialogComponent, {
      title:
        'You have been archived by the admin. You are no longer able to log into the  website. Please contact the assistance if there is any problem!',
    });

    this.router.navigate(['/']);
  }
}
