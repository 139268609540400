<div class="container">
  <button mat-icon-button class="close-button" aria-label="close dialog" [mat-dialog-close]="false">
    <mat-icon>close</mat-icon>
  </button>

  <p class="message">
    {{title}}
  </p>

  <button *ngIf="actionLabel" class="button--primary" (click)="actionClick()">
    {{actionLabel}}
  </button>

</div>