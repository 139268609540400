import {
    HttpClient,
    HttpContext,
    HttpErrorResponse,
    HttpHeaders,
    HttpParams,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { StoreRequestStateUpdater } from './types/store-request-state-updater';
import { ApiRequest } from './types/api-request';

@Injectable()
export class ApiService {
    constructor(private readonly httpClient: HttpClient) {}

    get(
        apiRequest: ApiRequest,
        requestStateUpdater: StoreRequestStateUpdater,
        queryParams: HttpParams = new HttpParams(),
    ): Observable<any> {
        requestStateUpdater(apiRequest.name, {
            inProgress: true,
        });
        return this.httpClient
            .get(apiRequest.url, { params: queryParams })
            .pipe(
                map((response) => {
                    requestStateUpdater(apiRequest.name, {
                        inProgress: false,
                    });
                    return response;
                }),
                catchError((error: HttpErrorResponse) => {
                    requestStateUpdater(apiRequest.name, {
                        inProgress: false,
                        error: true,
                    });
                    return throwError(error.error);
                }),
            );
    }

    put(
        apiRequest: ApiRequest,
        body: object = {},
        requestStateUpdater: StoreRequestStateUpdater,
    ): Observable<any> {
        requestStateUpdater(apiRequest.name, {
            inProgress: true,
        });
        return this.httpClient.put(apiRequest.url, JSON.stringify(body)).pipe(
            map((response) => {
                requestStateUpdater(apiRequest.name, {
                    inProgress: false,
                });
                return response;
            }),
            catchError((error: HttpErrorResponse) => {
                requestStateUpdater(apiRequest.name, {
                    inProgress: false,
                    error: true,
                });
                return throwError(error);
            }),
        );
    }

    post(
        apiRequest: ApiRequest,
        body: object = {},
        requestStateUpdater: StoreRequestStateUpdater,
        opts?: {
            headers?: HttpHeaders | {
                [header: string]: string | string[];
            };
            context?: HttpContext;
            observe?: 'body';
            params?: HttpParams | {
                [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
            };
            reportProgress?: boolean;
            responseType?: 'json';
            withCredentials?: boolean;
        }
    ): Observable<any> {
        requestStateUpdater(apiRequest.name, {
            inProgress: true,
        });
        return this.httpClient.post(apiRequest.url, JSON.stringify(body), opts).pipe(
            map((response) => {
                requestStateUpdater(apiRequest.name, {
                    inProgress: false,
                });
                return response;
            }),
            catchError((error: HttpErrorResponse) => {
                requestStateUpdater(apiRequest.name, {
                    inProgress: false,
                    error: true,
                });
                return throwError(error);
            }),
        );
    }

    patch(
        apiRequest: ApiRequest,
        body: object = {},
        requestStateUpdater: StoreRequestStateUpdater,
    ): Observable<any> {
        requestStateUpdater(apiRequest.name, {
            inProgress: true,
        });
        return this.httpClient.patch(apiRequest.url, JSON.stringify(body)).pipe(
            map((response) => {
                requestStateUpdater(apiRequest.name, {
                    inProgress: false,
                });
                return response;
            }),
            catchError((error: HttpErrorResponse) => {
                requestStateUpdater(apiRequest.name, {
                    inProgress: false,
                    error: true,
                });
                return throwError(error);
            }),
        );
    }

    delete(
        apiRequest: ApiRequest,
        requestStateUpdater: StoreRequestStateUpdater,
    ): Observable<any> {
        requestStateUpdater(apiRequest.name, {
            inProgress: true,
        });
        return this.httpClient.delete(apiRequest.url).pipe(
            map((response) => {
                requestStateUpdater(apiRequest.name, {
                    inProgress: false,
                });
                return response;
            }),
            catchError((error: HttpErrorResponse) => {
                requestStateUpdater(apiRequest.name, {
                    inProgress: false,
                    error: true,
                });
                return throwError(error);
            }),
        );
    }
}
