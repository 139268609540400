import { UserStoreState } from './user.store.state';
import { Store } from '@ba-infrastructure/store/store';
import { Observable, of, ReplaySubject, throwError, timer } from 'rxjs';
import { StoreRequestStateUpdater } from '@ba-infrastructure/api-client/types/store-request-state-updater';
import { UserEndpoint } from './user.endpoint';
import { catchError, map, switchMap, switchMapTo, tap } from 'rxjs/operators';
import * as endpointHelpers from '@ba-shared/helpers/endpoint.helpers';
import { JwtService } from '@ba-infrastructure/api-client/jwt.service';
import { Injectable } from '@angular/core';
import { LoginDto } from '@ba-core/user/types/dto/login.dto';
import { USER_ROLE } from '../types/role';
import { environment } from 'src/environments/environment';
import { Role, User, UserStatusEnum } from 'src/app/features/admin/user/types/user';
import { crypt, decrypt } from '@ba-shared/helpers/crypto';

@Injectable()
export class UserStore extends Store<UserStoreState> {
    user$: Observable<User>;
    private readonly salt = 'KIMAL2022';
    private readonly connectorStr = '@@@@@@@@@@';
    private storeRequestStateUpdater: StoreRequestStateUpdater;

    private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
    public isAuthenticated = this.isAuthenticatedSubject.asObservable();

    constructor(
        private readonly endpoint: UserEndpoint,
        private readonly jwtService: JwtService,
    ) {
        super(new UserStoreState());

        this.user$ = this.state$.pipe(map((state) => state.user));
        this.storeRequestStateUpdater = endpointHelpers.getStoreRequestStateUpdater(
            this,
        );

        const userToken = jwtService.getToken();
        if (userToken) {
            this.isAuthenticatedSubject.next(true);
        } else {
            this.isAuthenticatedSubject.next(false);
        }
    }

    get user(): User {
        return this.state.user;
    }

    get isAdmin(): boolean {
        return this.user?.roles?.includes(Role.Admin);
    }

    get isUser(): boolean {
        return this.user?.roles?.includes(Role.User);
    }

    loadUser(): Observable<User> {
        var userInLocalStorage = window.localStorage.getItem('user');
        if (userInLocalStorage) {
            const user = JSON.parse(userInLocalStorage) as User;
            this.setUser(user);
            return of(user);
        }
        return of(null);
    }

    fetchUserData(applicationUserId: string): Observable<User> {
        return this.endpoint.getApplicationUser(applicationUserId, this.storeRequestStateUpdater);
    }

    intervalCheckUserStatus(): Observable<UserStatusEnum> {
        return timer(1000, environment.intervalCheckArchiveUser).pipe(
            switchMap(() => {
                let user: User;
                const userStr = window.localStorage.getItem('user');
                user = JSON.parse(userStr) as User;

                return this.fetchUserData(user.id).pipe(
                    catchError(() => {
                        return of(null);
                    })
                )
            }),
            tap((user: User) => {
                this.updateUserData(user);
            }),
            map((user: User) => user?.status),
        );
    }

    updateUserData (user: User) {
        const newUser = {
            ...this.state.user,
            ...user
        };
        window.localStorage.setItem('user', JSON.stringify(newUser));
        this.setState({
            ...this.state,
            user: newUser,
        });
    } 

    login(dto: LoginDto): Observable<User> {
        return this.endpoint.login(dto, this.storeRequestStateUpdater).pipe(
            tap((user: User) => {
                this.setUser(user);
                this.checkRememberUser(dto);
            }),
        );
    }

    setPassword(password: string): Observable<boolean> {
        return this.endpoint.setPassword(password, this.storeRequestStateUpdater).pipe(
            map(() => true)
        );
    }

    resetPassword(email: string, password: string, activationCode: string): Observable<boolean> {
        return this.endpoint.resetPassword({ email, password, activationCode }, this.storeRequestStateUpdater).pipe(
            map(() => true)
        );
    }

    verifyAuthCode(activationCode: string): Observable<any> {
        return this.endpoint.verifyAuthCode(activationCode, this.storeRequestStateUpdater);
    }

    requestForgotPwd(email: string): Observable<boolean> {
        return this.endpoint.requestForgotPwd(email, this.storeRequestStateUpdater).pipe(
            map(() => true)
        );
    }

    getRememberUserLogin(): string | null {
        const rememberMe = window.localStorage.getItem('rememberMe');
        const userLogin = window.localStorage.getItem('userLogin');
        if (!rememberMe || !userLogin) {
            return null;
        }

        return this.decryptUserLogin(userLogin);
    }

    purgeAuth() {
        this.jwtService.destroyToken();
        this.setState({
            ...this.state,
            user: null,
        });
        window.localStorage.removeItem('user');
        this.jwtService.destroyToken();
        this.isAuthenticatedSubject.next(false);
    }

    private checkRememberUser(dto: LoginDto): void {
        if (dto.rememberMe) {
            window.localStorage.setItem('rememberMe', 'true');
            window.localStorage.setItem('userLogin', this.cryptUserLogin(dto));
        } else {
            window.localStorage.removeItem('rememberMe');
            window.localStorage.removeItem('userLogin');
        }
    }

    private setUser(user: User): void {
        this.jwtService.saveToken(user.token);
        window.localStorage.setItem('user', JSON.stringify(user));
        this.setState({
            ...this.state,
            user,
        });
        this.isAuthenticatedSubject.next(true);
    }

    private cryptUserLogin(dto: LoginDto): string {
        return crypt(this.salt, dto.email);
    }

    private decryptUserLogin(cryptPhrase: string): string | null {
        const email = decrypt(this.salt, cryptPhrase) as string;
        if (email?.length > 0) {
            return email;
        }

        return null;
    }
}
