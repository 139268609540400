import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { UserStore } from '../user/services/user.store';
import { take, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private readonly router: Router,
        private readonly userStore: UserStore,
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean> {
        return this.userStore.isAuthenticated.pipe(
            take(1),
            tap(isAuthenticated => {
                if (!isAuthenticated) {
                    this.userStore.purgeAuth();
                    this.router.navigate(['']);
                }
            })
        );
    }
}
