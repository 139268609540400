import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { StoreRequestStateUpdater } from '@ba-infrastructure/api-client/types/store-request-state-updater';
import { ApiService } from '@ba-infrastructure/api-client/api.service';
import { STATIC_CONFIG } from '../static.config';
import { IdName } from '../types/id-name';
import { IPrinter } from '../types/printer';
import { IContact } from '../types/contact';
import { map } from 'rxjs/operators';
import { IShipVia } from 'src/app/features/shipment/shipment-workbench/types/ship-via';

@Injectable()
export class StaticEndpoint {
    constructor(private readonly apiService: ApiService) {}

    getContacts(
        requestStateUpdater: StoreRequestStateUpdater,
    ): Observable<IContact[]> {
        const request = STATIC_CONFIG.requests.getContacts;
        return this.apiService.get(request, requestStateUpdater);
    }

    getCollectionPoints(
        requestStateUpdater: StoreRequestStateUpdater,
    ): Observable<IdName[]> {
        const request = STATIC_CONFIG.requests.getCollectionPoints;
        return this.apiService.get(request, requestStateUpdater);
    }

    getPrinters(
        requestStateUpdater: StoreRequestStateUpdater,
    ): Observable<IPrinter[]> {
        const request = STATIC_CONFIG.requests.getPrinters;
        return this.apiService.get(request, requestStateUpdater);
    }

    getShipmentYears(
        requestStateUpdater: StoreRequestStateUpdater,
    ): Observable<number[]> {
        const request = STATIC_CONFIG.requests.getShipmentYears;
        return this.apiService.get(request, requestStateUpdater).pipe(map(data => data.shipmentYears));
    }
    
    getShipVia(
        requestStateUpdater: StoreRequestStateUpdater,
    ): Observable<IShipVia[]> {
        const request = STATIC_CONFIG.requests.getShipVia;
        return this.apiService.get(request, requestStateUpdater);
    }
}
