import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'ba-page-not-found',
    templateUrl: './page-not-found.component.html',
    styleUrls: ['./page-not-found.component.sass'],
})
export class PageNotFoundComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
