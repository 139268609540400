import { IShipVia } from 'src/app/features/shipment/shipment-workbench/types/ship-via';
import { IContact } from '../types/contact';
import { IdName } from '../types/id-name';
import { IPrinter } from '../types/printer';
import { Requests } from '../types/requests';

export class StaticStoreState {
    collectionPoints: IdName[] = [];
    printers: IPrinter[] = [];
    contacts: IContact[] = [];
    shipVia: IShipVia[] = [];
    shipmentYears: number[] = [2022];
    requests: Requests = {
        getCollectionPoints: {},
        getPrinters: {},
        getContacts: {},
        getShipmentYears: {},
    };
}
