import { User } from 'src/app/features/admin/user/types/user';
import { Requests } from '../types/requests';

export class UserStoreState {
    user: User = null;
    requests: Requests = {
        login: {},
        getUser: {},
    };
}
