import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { CoreModule } from '@ba-core/core.module';
import { ViewsModule } from './views/views.module';
import { AppInitializationModule } from './app-initialization.module';
import { InfrastructureModule } from '@ba-infrastructure/infrastructure.module';
import { APP_CONFIG } from './app.config';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [AppComponent],
  imports: [
    // Angular modules
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,

    // Core "singleton" modules (not feature modules)
    InfrastructureModule,
    CoreModule,
    ViewsModule,

    MatSnackBarModule,
    MatDialogModule,

    // App initialization module
    AppInitializationModule,

    // Feature modules

    // App routing - should be the last import!
    AppRoutingModule,
  ],
  providers: [{ provide: 'BASE_API_URL', useValue: APP_CONFIG.apiBaseUrl }],
  bootstrap: [AppComponent],
})
export class AppModule { }
