export enum DHLShipmentType {
  DHL_NEXT_DAY = 1,
  DHL_NEXT_DAY_PRE_9AM = 2,
  DHL_NEXT_DAY_PRE_1030 = 3,
  DHL_NEXT_DAY_PRE_12 = 4,
  DHL_NEXT_SATURDAY = 5,
  DHL_48HR = 6,
  DHL_48HR_PLUS = 7,
  FEDEX_48HR = 8,
  FEDEX_NEXT_DAY = 9,        
  FEDEX_NEXT_DAY_PRE_9AM = 10,       
  FEDEX_NEXT_DAY_PRE_10AM = 11,       
  FEDEX_NEXT_DAY_PRE_12_NOON = 12, 
  FEDEX_SATURDAY = 13,
}

export const SHIP_TYPES = [
  {
    name: 'DHL NEXT DAY',
    value: DHLShipmentType.DHL_NEXT_DAY,
  },
  {
    name: 'DHL NEXT DAY PRE 9AM',
    value: DHLShipmentType.DHL_NEXT_DAY_PRE_9AM,
  },
  {
    name: 'DHL NEXT DAY PRE 10.30',
    value: DHLShipmentType.DHL_NEXT_DAY_PRE_1030,
  },
  {
    name: 'DHL NEXT DAY PRE 12',
    value: DHLShipmentType.DHL_NEXT_DAY_PRE_12,
  },
  {
    name: 'DHL NEXT SATURDAY',
    value: DHLShipmentType.DHL_NEXT_SATURDAY,
  },
  {
    name: 'DHL 48HR',
    value: DHLShipmentType.DHL_48HR,
  },
  {
    name: 'DHL 48HR+',
    value: DHLShipmentType.DHL_48HR_PLUS,
  },
  {
    name: 'FEDEX NEXT DAY',
    value: DHLShipmentType.FEDEX_48HR,
  },
  {
    name: 'FEDEX NEXT DAY',
    value: DHLShipmentType.FEDEX_NEXT_DAY,
  },
  {
    name: 'FEDEX PRE 9AM',
    value: DHLShipmentType.FEDEX_NEXT_DAY_PRE_9AM,
  },
  {
    name: 'FEDEX PRE 10AM',
    value: DHLShipmentType.FEDEX_NEXT_DAY_PRE_10AM,
  },
  {
    name: 'FEDEX PRE 12',
    value: DHLShipmentType.FEDEX_NEXT_DAY_PRE_12_NOON,
  },
  {
    name: 'FEDEX SATURDAY',
    value: DHLShipmentType.FEDEX_SATURDAY,
  },
];

export const isFedexShipmentType = (shipmentType: DHLShipmentType) : boolean => {
  const foundShipment = SHIP_TYPES.find(s => s.value === shipmentType);
  if (!foundShipment) {
    return false;
  }

  return foundShipment.name.toLocaleLowerCase().includes('fedex');
}
