export interface User {
  id: string;
  createdAt: string;
  email: string;
  firstName: string;
  lastName: string;
  name: string;
  phoneNumber: string;
  collectionPointId: string;
  collectionPointName: string;
  printerId: string;
  printerName: string;
  roles: Role[];
  status: UserStatusEnum;
  token: string;
  isFirstTimeLoggedIn: boolean;
}

export enum Role {
  User = 1,
  Admin = 2,
}

export enum UserStatusEnum {
  Active = 1,
  Archived = 2,
}

export const MAP_USER_STATUS_TO_LABEL = {
  [UserStatusEnum.Active]: 'Active',
  [UserStatusEnum.Archived]: 'Archived',
};

export const MAP_ROLE_NAME = {
  [Role.Admin]: 'Admin',
  [Role.User]: 'User',
};
