export const STATIC_CONFIG = {
    requests: {
        getCollectionPoints: {
            name: 'getCollectionPoints',
            url: 'collection-points',
        },
        getPrinters: {
            name: 'getPrinters',
            url: 'printers',
        },
        getContacts: {
            name: 'getContacts',
            url: 'contacts',
        },
        getShipmentYears: {
            name: 'getShipmentYears',
            url: 'reports/initial-params',
        },
        getShipVia: {
            name: 'getShipVia',
            url: 'ship-via',
        },
    },
};
