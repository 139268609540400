import { Store } from '@ba-infrastructure/store/store';
import { Observable } from 'rxjs';
import { StoreRequestStateUpdater } from '@ba-infrastructure/api-client/types/store-request-state-updater';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import * as endpointHelpers from '@ba-shared/helpers/endpoint.helpers';
import { Injectable } from '@angular/core';
import { StaticStoreState } from './static.store.state';
import { IdName } from '../types/id-name';
import { StaticEndpoint } from './static.endpoint';
import { IPrinter } from '../types/printer';
import { IContact } from '../types/contact';
import { convertShipViaToOpt, IShipViaOpt } from 'src/app/features/shipment/shipment-workbench/types/ship-via';

@Injectable()
export class StaticStore extends Store<StaticStoreState> {
    collectionPoints$: Observable<IdName[]>;
    shipmentYears$: Observable<number[]>;
    printers$: Observable<IPrinter[]>;
    contacts$: Observable<IContact[]>;

    private storeRequestStateUpdater: StoreRequestStateUpdater;

    constructor(
        private readonly endpoint: StaticEndpoint,
    ) {
        super(new StaticStoreState());

        this.collectionPoints$ = this.state$.pipe(map((state) => state.collectionPoints));
        this.shipmentYears$ = this.state$.pipe(map((state) => state.shipmentYears));
        this.printers$ = this.state$.pipe(map((state) => state.printers));
        this.storeRequestStateUpdater = endpointHelpers.getStoreRequestStateUpdater(
            this,
        );
    }

    get shipViaWithLabel$ (): Observable<IShipViaOpt[]> {
        return this.state$.pipe(
            map((state) => state.shipVia),
            distinctUntilChanged(),
            map((shipVia) => shipVia.map(convertShipViaToOpt)),
        )
    }

    initStaticData(): void {
        this.getCollectionPoints();
        this.getShipmentYears();
    }

    getContacts(): void {
        if (this.state.contacts.length === 0) {
            this.endpoint.getContacts(this.storeRequestStateUpdater).subscribe(contacts => {
                this.setState({
                    ...this.state,
                    contacts
                });
            });
        }
    }

    getPrinters(): void {
        if (this.state.printers.length === 0) {
            this.endpoint.getPrinters(this.storeRequestStateUpdater).subscribe(printers => {
                this.setState({
                    ...this.state,
                    printers
                });
            });
        }
    }

    getCollectionPoints(): void {
        if (this.state.collectionPoints.length === 0) {
            this.endpoint.getCollectionPoints(this.storeRequestStateUpdater).subscribe(collectionPoints => {
                this.setState({
                    ...this.state,
                    collectionPoints
                });
            });
        }
    }

    getShipmentYears(): void {
        this.endpoint.getShipmentYears(this.storeRequestStateUpdater).subscribe(shipmentYears => {
            this.setState({
                ...this.state,
                shipmentYears
            });
        });
    }
    
    getShipVia(): void {
        if (this.state.shipVia.length === 0) {
            this.endpoint.getShipVia(this.storeRequestStateUpdater).subscribe(shipVia => {
                this.setState({
                    ...this.state,
                    shipVia
                });
            });
        }
    }
}
