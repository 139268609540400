import { Injectable, OnDestroy } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  readonly commonMessage = 'Something went wrong, please try again';

  constructor(private snackBar: MatSnackBar) {}

  showSuccess(message: string, opts: MatSnackBarConfig<any> = {}) {
    this.snackBar.dismiss();
    this.snackBar.open(message, 'close', {
      duration: 3000,
      ...opts,
    });
  }

  showError(
    message: string = this.commonMessage,
    opts: MatSnackBarConfig<any> = {},
  ) {
    this.snackBar.open(message, 'close', {
      duration: 3000,
      ...opts,
    });
  }
}
