import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApiService } from '@ba-infrastructure/api-client/api.service';
import { JwtService } from '@ba-infrastructure/api-client/jwt.service';
import { HttpTokenInterceptor } from '@ba-infrastructure/api-client/interceptors/http.token.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BaseUrlInterceptor } from './api-client/interceptors/http.base-url.interceptor';

@NgModule({
    declarations: [],
    imports: [CommonModule],
    providers: [
        ApiService,
        JwtService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpTokenInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: BaseUrlInterceptor,
            multi: true,
        },
    ],
})
export class InfrastructureModule {}
