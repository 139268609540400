import { DHLShipmentType } from "./ship-type";

export interface IShipVia {
  id: number;
  name: string;
  code: string;
  serviceKey: string;
  dhlShipmentType: DHLShipmentType;
}

export interface IShipViaOpt extends IShipVia {
  label: string;
}

function preTransformShipVia (shipVia: IShipVia): IShipVia {
  if (shipVia.dhlShipmentType === DHLShipmentType.DHL_48HR_PLUS) {
    return {
      ...shipVia,
      code: '',
      name: ''
    }
  }

  return shipVia;
}

export function convertShipViaToOpt(shipVia: IShipVia): IShipViaOpt {
  const _shipVia = preTransformShipVia(shipVia);
  return {
    ..._shipVia,
    label: `${_shipVia.code} ${_shipVia.name}`,
  };
}
