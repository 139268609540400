export const USER_CONFIG = {
    requests: {
        getUser: {
            name: 'getUser',
            url: '',
        },
        getApplicationUser: {
            name: 'getApplicationUser',
            url: 'application-users/:applicationUserId',
        },
        login: {
            name: 'login',
            url: 'auth/log-in',
        },
        requestForgotPwd: {
            name: 'requestForgotPwd',
            url: 'auth/request-reset-password',
        },
        setPassword: {
            name: 'setPassword',
            url: 'auth/set-initial-password',
        },
        resetPassword: {
            name: 'resetPassword',
            url: 'auth/reset-password',
        },
        verifyAuthCode: {
            name: 'verifyAuthCode',
            url: 'auth/verify-activation-code',
        },
    },
};
