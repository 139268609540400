import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@ba-core/authentication/auth.guard';
import { PageNotFoundComponent } from './views/page-not-found/page-not-found.component';

const routes: Routes = [
    {
        path: '',
        loadChildren: () =>
            import('./features/authentication/authentication.module').then(
                (m) => m.AuthenticationModule,
            ),
    },
    {
        path: 'home',
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./features/home/home.module').then(
                (m) => m.HomeModule,
            ),
    },
    {
        path: 'contact',
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./features/contact/contact.module').then(
                (m) => m.ContactModule,
            ),
    },
    {
        path: '**',
        component: PageNotFoundComponent,
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
